import { createApi } from '@reduxjs/toolkit/query/react'

import { customToast } from '@/hooks'
import { baseQueryWithAuth } from '@/stores/redux/config'

import type { IFeedback, ISimpleResponse } from '@/interfaces'

export const feedbackApi = createApi({
  baseQuery: baseQueryWithAuth,
  reducerPath: 'feedbackApi',
  endpoints: (build) => ({
    createFeedback: build.mutation<ISimpleResponse, { body: Partial<IFeedback> }>({
      query: ({ body }) => ({
        url: '/feedback',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),
  }),
})

export const { useCreateFeedbackMutation } = feedbackApi
