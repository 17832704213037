import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import type { IAuthData, IAuthStore } from '@/interfaces'
import { UserRole, UserStatus } from '@/interfaces'

const initialData: IAuthData = {
  token: '',
  refresh_token: '',
  email_verified: false,
  need_2fa: false,
  first_login: false,
  setup_complete: false,
  user: {
    id: null,
    first_name: '',
    last_name: '',
    email: '',
    date_of_birth: '',
    prefix: '',
    speciality: '',
    settings: [],
    hospital: '',
    patient_units: false,
    city: '',
    first_login: false,
    setup_complete: false,
    intercom_ids: {
      web: '',
      android: '',
      ios: '',
    },
    organization: {
      id: '',
      name: '',
      timezone: null,
      settings: [],
      ehr_enabled: false,
      users: [],
    },
    roles: [UserRole.PROVIDER],
    status: UserStatus.TRIAL,
    is_billing_contact: false,
  },
}

export const useAuthStore = create<IAuthStore>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialData,

        handleSetAuthData: (values) => {
          set(values)
        },

        handleSetUser: (user) => {
          set({ user })
        },

        handleLogout: () => {
          set(initialData)
        },

        isAdmin: () => {
          const { roles } = get().user
          return roles.includes(UserRole.ADMIN)
        },

        isInactive: () => {
          return (
            get().user.status === UserStatus.INACTIVE ||
            get().user.status === UserStatus.FINISHED_TRIAL
          )
        },
      }),
      { name: 'authData' },
    ),
    { name: 'AuthStore' },
  ),
)
