import { createApi } from '@reduxjs/toolkit/query/react'

import { customToast } from '@/hooks'
import { baseQueryWithAuth } from '@/stores/redux/config'

import { parseNoteData } from '@/utils'

import type {
  IDataWithMeta,
  IDeepgramKeyResponse,
  IDeepgramTranscriptionResponse,
  IEncounter,
  IGetEncountersParams,
  IHPIData,
  IMergeHPIRequest,
  INote,
  INoteMergeResponse,
  IPatient,
  ISimpleResponse,
  IStartEncounterRequest,
  ITranscribeFileRequest,
  ITranscript,
  IVoiceEditContent,
  IVoiceEditResponse,
} from '@/interfaces'

export const encountersApi = createApi({
  baseQuery: baseQueryWithAuth,
  reducerPath: 'encountersApi',
  tagTypes: ['Encounter', 'NoteMerging'],
  endpoints: (build) => ({
    getEncounters: build.query<IDataWithMeta<IEncounter[]>, IGetEncountersParams>({
      query: ({ page, limit, visit_at, title, status, sort, isEhrEnabled, patientSearch }) => {
        const params = new URLSearchParams()

        if (page) params.append('page', page.toString())
        if (limit) params.append('limit', limit.toString())

        if (isEhrEnabled && patientSearch) {
          // Use patient search endpoint
          if (patientSearch.first_name) params.append('first_name', patientSearch.first_name)
          if (patientSearch.last_name) params.append('last_name', patientSearch.last_name)
          if (patientSearch.birthdate) params.append('birthdate', patientSearch.birthdate)
          if (patientSearch.search) params.append('search', patientSearch.search)

          return {
            url: '/encounters/patient-search',
            params,
            method: 'GET',
          }
        } else {
          // Use regular encounters endpoint
          if (visit_at) params.append('visit_at', visit_at.toString())
          if (title) params.append('title', title)
          if (status && status.length > 0) {
            for (const s of status) params.append('status[]', s)
          }
          if (sort) {
            params.append('sort[column]', sort.column)
            params.append('sort[direction]', sort.direction)
          }

          return {
            url: '/encounters',
            params,
            method: 'GET',
          }
        }
      },
      providesTags: ['Encounter'],
      transformResponse: (baseQueryReturnValue: IDataWithMeta<IEncounter[]>) => {
        return baseQueryReturnValue
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      serializeQueryArgs: ({ queryArgs }) => queryArgs,
      merge: (currentCache, newItems) => {
        if (newItems.meta && newItems.meta.current_page === 1) {
          currentCache.data = newItems.data
        } else {
          currentCache.data.push(...newItems.data)
        }
        if (newItems.meta) {
          currentCache.meta = newItems.meta
        }
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.page !== previousArg?.page ||
        currentArg?.title !== previousArg?.title ||
        currentArg?.status !== previousArg?.status,
    }),

    // getEncounters: build.query<IEncounter[], { visit_at: IEncounter['visit_at'] }>({
    //   query: ({ visit_at }) => ({
    //     url: '/encounters',
    //     params: { visit_at },
    //     method: 'GET',
    //   }),
    //   providesTags: ['Encounter'],
    //   transformResponse: (baseQueryReturnValue: { data: IEncounter[] }) => {
    //     return baseQueryReturnValue.data
    //   },
    //   transformErrorResponse: (baseQueryReturnValue: any) => {
    //     customToast(baseQueryReturnValue.data.message, 'error')
    //   },
    // }),

    getCompletedEncounters: build.query<
      IDataWithMeta<IEncounter[]>,
      { page: number; limit: number; visit_at: IEncounter['visit_at'] }
    >({
      query: (params) => ({
        url: '/encounters/completed',
        params,
        method: 'GET',
      }),
      providesTags: ['Encounter'],
      transformResponse: (baseQueryReturnValue: IDataWithMeta<IEncounter[]>) => {
        return baseQueryReturnValue
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      serializeQueryArgs: ({ queryArgs: { page, ...args } }) => args,
      merge: (currentCache, newItems) => {
        if (newItems.meta.current_page === 1) {
          currentCache.data = newItems.data
        } else {
          currentCache.data.push(...newItems.data)
        }
      },
      forceRefetch: ({ currentArg, previousArg }) => currentArg?.page !== previousArg?.page,
    }),

    getEncounter: build.query<IEncounter, { id: IEncounter['id'] }>({
      query: ({ id }) => ({
        url: `/encounters/${id}`,
        method: 'GET',
      }),
      providesTags: ['Encounter'],
      transformResponse: (baseQueryReturnValue: { data: IEncounter }) => {
        const encounter = baseQueryReturnValue.data

        if (encounter.transcript) {
          try {
            encounter.transcript.parsedTranscript = JSON.parse(encounter.transcript.transcript)
          } catch {
            encounter.transcript.parsedTranscript = []
          }

          try {
            encounter.transcript.parsedOriginal = JSON.parse(encounter.transcript.original)
          } catch {
            encounter.transcript.parsedOriginal = []
          }
        }

        if (encounter.note) {
          encounter.note = parseNoteData(encounter.note)
        }

        return encounter
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    getInProgressEncounters: build.query<
      IDataWithMeta<IEncounter[]>,
      { page: number; limit: number }
    >({
      query: (params) => ({
        url: '/encounters/in-progress',
        params,
        method: 'GET',
      }),
      providesTags: ['Encounter'],
      transformResponse: (baseQueryReturnValue: IDataWithMeta<IEncounter[]>) => {
        return baseQueryReturnValue
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      serializeQueryArgs: ({ queryArgs: { page, ...args } }) => args,
      merge: (currentCache, newItems) => {
        if (newItems.meta.current_page === 1) {
          currentCache.data = newItems.data
        } else {
          currentCache.data.push(...newItems.data)
        }
      },
      forceRefetch: ({ currentArg, previousArg }) => currentArg?.page !== previousArg?.page,
    }),

    getRecentEncounters: build.query<IDataWithMeta<IEncounter[]>, { page: number; limit: number }>({
      query: (params) => ({
        url: '/encounters/recent',
        params,
        method: 'GET',
      }),
      providesTags: ['Encounter'],
      transformResponse: (baseQueryReturnValue: IDataWithMeta<IEncounter[]>) => {
        return baseQueryReturnValue
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      serializeQueryArgs: ({ queryArgs: { page, ...args } }) => args,
      merge: (currentCache, newItems) => {
        if (newItems.meta.current_page === 1) {
          currentCache.data = newItems.data
        } else {
          currentCache.data.push(...newItems.data)
        }
      },
      forceRefetch: ({ currentArg, previousArg }) => currentArg?.page !== previousArg?.page,
    }),

    getNote: build.query<INote, { id: INote['id'] }>({
      query: ({ id }) => ({
        url: `/notes/${id}`,
        method: 'GET',
      }),
      transformResponse: (baseQueryReturnValue: INote) => {
        return parseNoteData(baseQueryReturnValue)
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    downloadNotePDF: build.query<string, { id: INote['id'] }>({
      query: ({ id }) => ({
        url: `/notes/${id}/download`,
        method: 'GET',
        responseHandler: (response) => response.blob(),
      }),
      providesTags: ['Encounter'],
      transformResponse: (baseQueryReturnValue: Blob) => {
        return URL.createObjectURL(baseQueryReturnValue)
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    createEncounter: build.mutation<
      IEncounter,
      {
        visit_at: string
        patient_id?: string
      }
    >({
      query: (body) => ({
        url: '/encounters',
        method: 'POST',
        body,
      }),
      transformResponse: (baseQueryReturnValue: { data: IEncounter }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Encounter'],
    }),

    addNewRecord: build.mutation<
      ISimpleResponse,
      {
        encounter_id: string
        transcript: string
        recorded: boolean
        duration: number
        lang?: IEncounter['language']
      }
    >({
      query: (body) => ({
        url: '/transcripts/add-new-record',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Encounter'],
    }),

    reRecord: build.mutation<IEncounter, IEncounter['id']>({
      query: (encounterId) => ({
        url: `/encounters/${encounterId}/re-record`,
        method: 'POST',
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Encounter'],
    }),

    finishRecording: build.mutation<IEncounter, { encounterId: string }>({
      query: ({ encounterId }) => ({
        url: `/encounters/${encounterId}/finish-recording`,
        method: 'POST',
      }),
      invalidatesTags: ['Encounter'],
    }),

    updateEncounterTranscript: build.mutation<
      ITranscript,
      { id: ITranscript['id']; body: Partial<ITranscript> }
    >({
      query: ({ body, id }) => ({
        url: `/transcripts/${id}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (baseQueryReturnValue: { data: ITranscript }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Encounter'],
    }),

    updateTranscriptRating: build.mutation<
      ISimpleResponse,
      { id: INote['id']; rating: INote['rating'] }
    >({
      query: ({ id, rating }) => ({
        url: `/transcripts/${id}/set-rating`,
        method: 'POST',
        body: {
          rating,
        },
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Encounter'],
    }),

    getNoteHPI: build.query<IHPIData[], { id: string | undefined }>({
      query: ({ id }) => ({
        url: `/note-merging/${id}/hpi`,
        method: 'GET',
      }),
      providesTags: ['NoteMerging'],
      transformResponse: (baseQueryReturnValue: { data: IHPIData[] }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    mergeNoteHPI: build.mutation<
      INoteMergeResponse,
      {
        id: string
        body: { parts: IMergeHPIRequest[] }
      }
    >({
      query: ({ id, body }) => ({
        url: `/note-merging/${id}/hpi`,
        method: 'POST',
        body,
      }),
      transformResponse: (baseQueryReturnValue: INoteMergeResponse) => {
        return baseQueryReturnValue
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['NoteMerging'],
    }),

    unmergeNoteHPI: build.mutation<INote, { id: string }>({
      query: ({ id }) => ({
        url: `/note-merging/${id}/hpi/unmerge`,
        method: 'POST',
      }),
      transformResponse: (baseQueryReturnValue: INote) => {
        return parseNoteData(baseQueryReturnValue)
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['NoteMerging'],
    }),

    regenerateTranscript: build.mutation<ISimpleResponse, { encounter_id: IEncounter['id'] }>({
      query: (body) => ({
        url: '/transcripts/regenerate',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Encounter'],
    }),

    updateEncounterNote: build.mutation<INote, { id: INote['id']; body: Partial<INote> }>({
      query: ({ body, id }) => ({
        url: `/notes/${id}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (baseQueryReturnValue: { data: INote }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Encounter'],
    }),

    generateNote: build.mutation<
      ISimpleResponse,
      {
        transcript_id: ITranscript['id']
        note_type_id: INote['note_type_id']
      }
    >({
      query: (body) => ({
        url: '/notes/generate',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Encounter'],
    }),

    removeNote: build.mutation<INote, { id: INote['id'] }>({
      query: ({ id }) => ({
        url: `/notes/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (baseQueryReturnValue: { data: INote }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Encounter'],
    }),

    assignPatientEncounter: build.mutation<
      IEncounter,
      { id: IEncounter['id']; patient_id: IPatient['id'] }
    >({
      query: ({ id, patient_id }) => ({
        url: `/encounters/${id}/assign-patient`,
        body: { patient_id },
        method: 'POST',
      }),
      transformResponse: (baseQueryReturnValue: { data: IEncounter }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Encounter'],
    }),

    completeEncounter: build.mutation<IEncounter, { id: IEncounter['id'] }>({
      query: ({ id }) => ({
        url: `/encounters/${id}/complete`,
        method: 'POST',
      }),
      transformResponse: (baseQueryReturnValue: { data: IEncounter }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Encounter'],
    }),

    deleteEncounter: build.mutation<null, { id: IEncounter['id'] }>({
      query: ({ id }) => ({
        url: `/encounters/${id}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Encounter'],
    }),

    bulkDeleteEncounter: build.mutation<number, { encounters: IEncounter['id'][] }>({
      query: ({ encounters }) => ({
        url: '/bulk-actions/encounters',
        method: 'DELETE',
        body: { encounters },
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Encounter'],
    }),

    bulkCloseEncounter: build.mutation<number, { encounters: IEncounter['id'][] }>({
      query: ({ encounters }) => ({
        url: '/bulk-actions/encounters/close',
        method: 'POST',
        body: { encounters },
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Encounter'],
    }),

    touchEncounter: build.mutation<IEncounter, { id: IEncounter['id'] }>({
      query: ({ id }) => ({
        url: `/encounters/${id}/interaction`,
        method: 'POST',
      }),
      transformResponse: (baseQueryReturnValue: { data: IEncounter }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Encounter'],
    }),

    generateTranscriptApiKey: build.mutation<IDeepgramKeyResponse, object>({
      query: () => ({
        url: '/deepgram/key',
        method: 'POST',
        body: {},
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Encounter'],
    }),

    voiceEdit: build.mutation<IVoiceEditContent[], { id: INote['id']; prompt: string }>({
      query: ({ prompt, id }) => ({
        url: `/notes/${id}/voice-edit`,
        method: 'POST',
        body: { prompt },
      }),
      transformResponse: (baseQueryReturnValue: IVoiceEditResponse) => {
        return baseQueryReturnValue.changed_content
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data?.message || 'An error occurred', 'error')
      },
      invalidatesTags: ['Encounter'],
    }),

    transcribeFile: build.mutation<IDeepgramTranscriptionResponse, ITranscribeFileRequest>({
      query: (formData) => ({
        url: '/deepgram/transcribe',
        method: 'POST',
        body: formData,
        formData: true,
        headers: {
          Accept: 'application/json',
        },
      }),
      transformErrorResponse: (error: any) => {
        customToast(error.data?.message || 'Error transcribing file', 'error')
        return error
      },
    }),

    startEncounter: build.mutation<
      IEncounter,
      { id: IEncounter['id']; body: IStartEncounterRequest }
    >({
      query: ({ id, body }) => ({
        url: `/encounters/${id}/start`,
        method: 'POST',
        body,
      }),
      transformResponse: (baseQueryReturnValue: { data: IEncounter }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Encounter'],
    }),

    setTitle: build.mutation<IEncounter, { id: IEncounter['id']; title: string }>({
      query: ({ id, title }) => ({
        url: `/encounters/${id}/title`,
        method: 'PUT',
        body: { title },
      }),
      transformResponse: (baseQueryReturnValue: { data: IEncounter }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Encounter'],
    }),

    setPatientContext: build.mutation<
      IEncounter,
      { id: IEncounter['id']; patient_context: string }
    >({
      query: ({ id, patient_context }) => ({
        url: `/encounters/${id}/patient-context`,
        method: 'PUT',
        body: { patient_context },
      }),
      transformResponse: (baseQueryReturnValue: { data: IEncounter }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Encounter'],
    }),

    updateEncounterRating: build.mutation<
      ISimpleResponse,
      { id: IEncounter['id']; rating: IEncounter['rating'] }
    >({
      query: ({ id, rating }) => ({
        url: `/encounters/${id}/set-rating`,
        method: 'POST',
        body: { rating },
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Encounter'],
    }),

    getVisits: build.query<
      IDataWithMeta<IEncounter[]>,
      {
        page: number
        limit: number
        visit_at?: IEncounter['visit_at']
        title?: string
        status?: IEncounter['status'][]
        sort?: {
          column: 'interacted_at' | 'started_at' | 'visit_at'
          direction: 'asc' | 'desc'
        }
        isEhrEnabled: boolean
        patientSearch?: {
          first_name?: string
          last_name?: string
          birthdate?: string
          search?: string
        }
      }
    >({
      query: ({ page, limit, visit_at, title, status, sort, isEhrEnabled, patientSearch }) => {
        const params = new URLSearchParams({
          page: page.toString(),
          limit: limit.toString(),
        })

        if (isEhrEnabled && patientSearch) {
          // Use patient search endpoint
          if (patientSearch.first_name) params.append('first_name', patientSearch.first_name)
          if (patientSearch.last_name) params.append('last_name', patientSearch.last_name)
          if (patientSearch.birthdate) params.append('birthdate', patientSearch.birthdate)
          if (patientSearch.search) params.append('search', patientSearch.search)

          return {
            url: '/encounters/patient-search',
            params,
            method: 'GET',
          }
        } else {
          // Use regular encounters endpoint
          if (visit_at) params.append('visit_at', visit_at.toString())
          if (title) params.append('title', title)
          if (status && status.length > 0) {
            for (const s of status) params.append('status[]', s)
          }
          if (sort) {
            params.append('sort[column]', sort.column)
            params.append('sort[direction]', sort.direction)
          }

          return {
            url: '/encounters',
            params,
            method: 'GET',
          }
        }
      },
      providesTags: ['Encounter'],
      transformResponse: (baseQueryReturnValue: IDataWithMeta<IEncounter[]>) => {
        return baseQueryReturnValue
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      serializeQueryArgs: ({ queryArgs }) => queryArgs,
      merge: (currentCache, newItems) => {
        if (newItems.meta.current_page === 1) {
          currentCache.data = newItems.data
        } else {
          currentCache.data.push(...newItems.data)
        }
        currentCache.meta = newItems.meta
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.page !== previousArg?.page ||
        currentArg?.title !== previousArg?.title ||
        currentArg?.status !== previousArg?.status,
    }),
  }),
})

export const {
  useGetInProgressEncountersQuery,
  useGetEncounterQuery,
  useGetNoteHPIQuery,
  useMergeNoteHPIMutation,
  useGetCompletedEncountersQuery,
  useGetRecentEncountersQuery,
  useGetEncountersQuery,
  useDownloadNotePDFQuery,
  useLazyDownloadNotePDFQuery,
  useCreateEncounterMutation,
  useAddNewRecordMutation,
  useReRecordMutation,
  useFinishRecordingMutation,
  useGenerateNoteMutation,
  useUpdateEncounterTranscriptMutation,
  useUpdateTranscriptRatingMutation,
  useRegenerateTranscriptMutation,
  useUpdateEncounterNoteMutation,
  useRemoveNoteMutation,
  useAssignPatientEncounterMutation,
  useCompleteEncounterMutation,
  useTouchEncounterMutation,
  useGenerateTranscriptApiKeyMutation,
  useUnmergeNoteHPIMutation,
  useGetNoteQuery,
  useVoiceEditMutation,
  useTranscribeFileMutation,
  useDeleteEncounterMutation,
  useBulkDeleteEncounterMutation,
  useBulkCloseEncounterMutation,
  useStartEncounterMutation,
  useSetTitleMutation,
  useSetPatientContextMutation,
  useUpdateEncounterRatingMutation,
  useGetVisitsQuery,
} = encountersApi
