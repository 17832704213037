import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import type { IModal, IModalStore } from '@/interfaces'

const initialData: IModal = {
  active: false,
  body: null,
}

export const useModalStore = create<IModalStore>()(
  devtools(
    (set) => ({
      ...initialData,

      handleSetModal: (values) => {
        set(values)
      },

      closeModal: () => {
        set(initialData)
      },
    }),
    { name: 'ModalStore' },
  ),
)
