import type React from 'react'

import { DLogo } from '@/constants/icons'

import './app-preloader.scss'

const AppPreloader: React.FC = () => {
  return (
    <div className='app_preloader'>
      <div className='app_preloader__content'>
        <DLogo width={120} />
        <div className='app_preloader__text'>Getting things ready...</div>
      </div>
    </div>
  )
}

export default AppPreloader
