import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQueryWithAuth } from '@/stores/redux/config'

import type { IKeyword, ISimpleResponse } from '@/interfaces'

export const keywordsApi = createApi({
  baseQuery: baseQueryWithAuth,
  reducerPath: 'keywordsApi',
  tagTypes: ['Keywords'],
  endpoints: (build) => ({
    getKeywords: build.query<IKeyword[], void>({
      query: () => '/keywords',
      providesTags: ['Keywords'],
    }),

    addKeyword: build.mutation<IKeyword, Partial<IKeyword>>({
      query: (body) => ({
        url: '/keywords',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Keywords'],
    }),

    deleteKeyword: build.mutation<ISimpleResponse, { id: IKeyword['id'] }>({
      query: ({ id }) => ({
        url: `/keywords/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Keywords'],
    }),

    updateKeyword: build.mutation<IKeyword, { id: IKeyword['id']; body: Partial<IKeyword> }>({
      query: ({ id, body }) => ({
        url: `/keywords/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Keywords'],
    }),
  }),
})

export const {
  useGetKeywordsQuery,
  useAddKeywordMutation,
  useDeleteKeywordMutation,
  useUpdateKeywordMutation,
} = keywordsApi
