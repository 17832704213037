import { createApi } from '@reduxjs/toolkit/query/react'

import { customToast } from '@/hooks'
import { baseQuery } from '@/stores/redux/config'

import type {
  ICheck2FACodeData,
  IForgotPasswordData,
  ILoginAsFormData,
  ILoginFormData,
  ILoginRes,
  ISetNewPasswordData,
  ISignUpFormData,
  ISignUpRes,
  ISimpleResponse,
} from '@/interfaces'

export const authApi = createApi({
  baseQuery,
  reducerPath: 'authApi',
  endpoints: (build) => ({
    signUp: build.mutation<ISignUpRes, ISignUpFormData>({
      query: (body) => ({
        url: '/signup',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    signIn: build.mutation<ILoginRes, ILoginFormData>({
      query: (body) => ({
        url: '/login',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    signInAs: build.mutation<ILoginRes, ILoginAsFormData>({
      query: (body) => ({
        url: '/login-as',
        method: 'POST',
        body,
        credentials: 'include',
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    resendEmail: build.mutation<ISimpleResponse, void>({
      query: () => ({
        url: '/resend-verification-email',
        method: 'POST',
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    forgotPassword: build.mutation<ISimpleResponse, IForgotPasswordData>({
      query: (body) => ({
        url: '/forgot-password',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    resetPassword: build.mutation<ISimpleResponse, ISetNewPasswordData>({
      query: (body) => ({
        url: '/reset-password',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    check2FACode: build.mutation<ISimpleResponse, ICheck2FACodeData>({
      query: (params) => {
        return {
          url: '/check-2fa-code',
          params,
          method: 'POST',
        }
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    resend2FACode: build.mutation<ISimpleResponse, void>({
      query: () => {
        return {
          url: '/resend-2fa',
          method: 'POST',
        }
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),
  }),
})

export const {
  useSignInMutation,
  useSignUpMutation,
  useSignInAsMutation,
  useResendEmailMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useCheck2FACodeMutation,
  useResend2FACodeMutation,
} = authApi
