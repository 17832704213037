import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import type { ITimer, ITimerStore } from '@/interfaces'

const initialData: ITimer = {
  idleTimestamp: null,
}

export const useTimerStore = create<ITimerStore>()(
  devtools(
    persist(
      (set) => ({
        ...initialData,

        touchIdleTimestamp() {
          set({ idleTimestamp: Date.now() })
        },

        resetTimers() {
          set(initialData)
        },
      }),
      { name: 'timerData' },
    ),
    { name: 'TimerStore' },
  ),
)
