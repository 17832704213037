import type { IUserSettingsItem } from './ui-interfaces'

export interface ILoginFormData {
  email: string
  password: string
}

export interface ILoginAsFormData {
  user_id: string
}

export interface ILoginRes {
  token: string
  refresh_token: string
  email_verified: boolean
  need_2fa: boolean
  first_login: boolean
  setup_complete: boolean
}

export type ISignUpRes = ILoginRes

export interface ISignUpFormData extends ILoginFormData {
  first_name: string
  last_name: string
  password_confirmation: string
  organization_name: string
}

export interface IForgotPasswordData {
  email: string
}

export interface IChangePasswordData {
  new_password: string
  current_password: string
  confirmation_password?: string
}

export interface ISetPasswordData {
  new_password: string
}

export interface ISetNewPasswordData {
  password: string
  password_confirmation: string
  token: string
  email: string
}

export interface ICheck2FACodeData {
  two_factor_code: string
}

export interface INoteMergeResponse {
  status: string
  message: string
}

export interface ISimpleResponse {
  message: string
}

export interface IUpdateUserSettingsData {
  hpi_merging?: boolean
  note_auto_submission_to_ehr?: boolean
  hide_recording_permission_prompt?: boolean
}

export interface ISimpleResponseWithJobId extends ISimpleResponse {
  job_id: string
}

export interface IUploadLink {
  url: string
}

export interface ITimezone {
  timezone: string
}

export interface IUser {
  created_at?: Date
  updated_at?: Date
  id: string | null
  email_verified_at?: Date | null
  email: string
  first_name: string
  image?: string
  last_name: string
  date_of_birth: string | Date
  prefix: string
  speciality: string
  patient_units: boolean
  hospital: string
  city: string
  password?: string
  new_password?: string
  password_confirmation?: string
  first_login: boolean
  setup_complete: boolean
  organization: IOrganization
  intercom_ids: IntercomIds
  settings: IUserSettingsItem[]
  roles: UserRole[]
  status: UserStatus
  is_billing_contact: boolean
}

export enum UserRole {
  PROVIDER = 'provider',
  ADMIN = 'organization_admin',
}

export enum UserStatus {
  TRIAL = 0,
  ACTIVE = 1,
  FINISHED_TRIAL = 2,
  INACTIVE = 3,
}

export enum AppVersion {
  STANDALONE = 'standalone',
  INTEGRATED = 'integrated',
}

export enum Timezone {
  AMERICA_ADAK = 'America/Adak',
  AMERICA_ANCHORAGE = 'America/Anchorage',
  AMERICA_BOISE = 'America/Boise',
  AMERICA_CHICAGO = 'America/Chicago',
  AMERICA_DENVER = 'America/Denver',
  AMERICA_DETROIT = 'America/Detroit',
  AMERICA_INDIANA_INDIANAPOLIS = 'America/Indiana/Indianapolis',
  AMERICA_INDIANA_KNOX = 'America/Indiana/Knox',
  AMERICA_INDIANA_MARENGO = 'America/Indiana/Marengo',
  AMERICA_INDIANA_PETERSBURG = 'America/Indiana/Petersburg',
  AMERICA_INDIANA_TELL_CITY = 'America/Indiana/Tell_City',
  AMERICA_INDIANA_VEVAY = 'America/Indiana/Vevay',
  AMERICA_INDIANA_VINCENNES = 'America/Indiana/Vincennes',
  AMERICA_INDIANA_WINAMAC = 'America/Indiana/Winamac',
  AMERICA_JUNEAU = 'America/Juneau',
  AMERICA_KENTUCKY_LOUISVILLE = 'America/Kentucky/Louisville',
  AMERICA_KENTUCKY_MONTICELLO = 'America/Kentucky/Monticello',
  AMERICA_LOS_ANGELES = 'America/Los_Angeles',
  AMERICA_MENOMINEE = 'America/Menominee',
  AMERICA_METLAKATLA = 'America/Metlakatla',
  AMERICA_NEW_YORK = 'America/New_York',
  AMERICA_NOME = 'America/Nome',
  AMERICA_NORTH_DAKOTA_BEULAH = 'America/North_Dakota/Beulah',
  AMERICA_NORTH_DAKOTA_CENTER = 'America/North_Dakota/Center',
  AMERICA_NORTH_DAKOTA_NEW_SALEM = 'America/North_Dakota/New_Salem',
  AMERICA_PHOENIX = 'America/Phoenix',
  AMERICA_SITKA = 'America/Sitka',
  AMERICA_YAKUTAT = 'America/Yakutat',
  PACIFIC_HONOLULU = 'Pacific/Honolulu',
  AMERICA_BLANC_SABLON = 'America/Blanc-Sablon',
  AMERICA_CAMBRIDGE_BAY = 'America/Cambridge_Bay',
  AMERICA_CRESTON = 'America/Creston',
  AMERICA_DAWSON = 'America/Dawson',
  AMERICA_DAWSON_CREEK = 'America/Dawson_Creek',
  AMERICA_EDMONTON = 'America/Edmonton',
  AMERICA_FORT_NELSON = 'America/Fort_Nelson',
  AMERICA_GLACE_BAY = 'America/Glace_Bay',
  AMERICA_GOOSE_BAY = 'America/Goose_Bay',
  AMERICA_HALIFAX = 'America/Halifax',
  AMERICA_INUVIK = 'America/Inuvik',
  AMERICA_IQALUIT = 'America/Iqaluit',
  AMERICA_MONCTON = 'America/Moncton',
  AMERICA_MONTREAL = 'America/Montreal',
  AMERICA_NIPIGON = 'America/Nipigon',
  AMERICA_PANGNIRTUNG = 'America/Pangnirtung',
  AMERICA_RAINY_RIVER = 'America/Rainy_River',
  AMERICA_RANKIN_INLET = 'America/Rankin_Inlet',
  AMERICA_REGINA = 'America/Regina',
  AMERICA_RESOLUTE = 'America/Resolute',
  AMERICA_ST_JOHNS = 'America/St_Johns',
  AMERICA_SWIFT_CURRENT = 'America/Swift_Current',
  AMERICA_THUNDER_BAY = 'America/Thunder_Bay',
  AMERICA_TORONTO = 'America/Toronto',
  AMERICA_VANCOUVER = 'America/Vancouver',
  AMERICA_WHITEHORSE = 'America/Whitehorse',
  AMERICA_WINNIPEG = 'America/Winnipeg',
  AMERICA_YELLOWKNIFE = 'America/Yellowknife',
  UTC = 'UTC',
}

export interface IntercomIds {
  web: string
  android: string
  ios: string
}

export interface IVoiceEditResponse {
  changed_content: IVoiceEditContent[]
}

export interface IVoiceEditContent {
  order: number
  title: string
  content: string
}

export interface IAuthData extends ILoginRes {
  user: IUser
}

export interface IAuthStore extends IAuthData {
  handleSetAuthData: (values: Partial<IAuthData>) => void
  handleLogout: () => void
  handleSetUser: (user: IUser) => void
  isAdmin: () => boolean
  isInactive: () => boolean
}

export interface IPasswordData {
  password: string
  new_password: string
  password_confirmation: string
}

export interface IOrganization {
  id: string
  name: string
  timezone: Timezone | null
  settings: {
    field_name: string
    value: string
  }[]
  ehr_enabled: boolean
  users: IUser[]
}

export interface IStartEncounterRequest {
  language: Language
  note_type_id: number
  note_generation_mode: NoteGenerationMode
}

export enum Language {
  ENGLISH = 'en-US',
  SPANISH = 'es',
  MULTI = 'multi',
}

export enum NoteGenerationMode {
  NORMAL = 'normal',
  INSTANT = 'instant',
  LEGACY = 'legacy',
  FAST = 'fast',
}
