import type { ComponentPropsWithoutRef } from 'react'
import { forwardRef } from 'react'
import cc from 'classcat'

import styles from './checkbox.module.scss'

type CheckboxProps = ComponentPropsWithoutRef<'input'>

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(
  { className, ...props },
  ref,
) {
  return <input type='checkbox' className={cc([styles.checkbox, className])} ref={ref} {...props} />
})
