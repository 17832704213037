import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import {
  authApi,
  encountersApi,
  feedbackApi,
  keywordsApi,
  macrosApi,
  noteTypesApi,
  organizationApi,
  patientsApi,
  profileApi,
} from './apis'

const rootReducer = {
  [authApi.reducerPath]: authApi.reducer,
  [encountersApi.reducerPath]: encountersApi.reducer,
  [feedbackApi.reducerPath]: feedbackApi.reducer,
  [noteTypesApi.reducerPath]: noteTypesApi.reducer,
  [patientsApi.reducerPath]: patientsApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [organizationApi.reducerPath]: organizationApi.reducer,
  [keywordsApi.reducerPath]: keywordsApi.reducer,
  [macrosApi.reducerPath]: macrosApi.reducer,
}

// Redux store config
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    // eslint-disable-next-line unicorn/prefer-spread
    getDefaultMiddleware().concat(
      authApi.middleware,
      encountersApi.middleware,
      feedbackApi.middleware,
      noteTypesApi.middleware,
      patientsApi.middleware,
      profileApi.middleware,
      organizationApi.middleware,
      keywordsApi.middleware,
      macrosApi.middleware,
    ),
  devTools: import.meta.env.DEV,
})

setupListeners(store.dispatch)
