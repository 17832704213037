import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import type { INoteStudioState, INoteStudioStore, SavingStatus } from '@/interfaces'

const initialData: INoteStudioState = {
  currentType: null,
  currentComponent: null,
  currentMode: null,
  noteTuneCount: 0,
  enabledSections: {},
  lastModified: null,
  savingStatus: null,
}

export const useNoteStudioStore = create<INoteStudioStore>()(
  devtools(
    persist(
      (set) => ({
        ...initialData,

        setCurrentType(currentType, resetMode = true) {
          set(resetMode ? { currentType, currentMode: null } : { currentType })
        },

        setCurrentComponent(currentComponent, resetMode = true) {
          set(resetMode ? { currentComponent, currentMode: null } : { currentComponent })
        },

        setCurrentMode(currentMode) {
          if (currentMode === 'new-type') {
            set({ currentType: null, currentComponent: null, currentMode })
          } else if (currentMode === 'new-component') {
            set({ currentComponent: null, currentMode })
          } else {
            set({ currentMode })
          }
        },

        setNoteTuneCount(noteTuneCount) {
          set({ noteTuneCount })
        },

        setEnabledSections(noteTypeId, sections) {
          set((state) => ({
            enabledSections: {
              ...state.enabledSections,
              [noteTypeId]: sections,
            },
          }))
        },

        reset() {
          set(initialData)
        },

        setLastModified: (timestamp: number) => set({ lastModified: timestamp }),

        setSavingStatus: (status: SavingStatus | null) => set({ savingStatus: status }),
      }),
      {
        name: 'note-studio-storage',
        partialize: (state) => ({ enabledSections: state.enabledSections }),
      },
    ),
    { name: 'NoteStudioStore' },
  ),
)
