import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQueryWithAuth } from '@/stores/redux/config'

import type { IMacro, ISimpleResponse } from '@/interfaces'

export const macrosApi = createApi({
  baseQuery: baseQueryWithAuth,
  reducerPath: 'macrosApi',
  tagTypes: ['Macros'],
  endpoints: (build) => ({
    getMacros: build.query<IMacro[], void>({
      query: () => '/macros',
      transformResponse: (response: { data: IMacro[] }) => {
        return response.data
      },
      providesTags: ['Macros'],
    }),

    getMacro: build.query<IMacro, { id: IMacro['id'] }>({
      query: ({ id }) => `/macros/${id}`,
      transformResponse: (response: { data: IMacro }) => {
        return response.data
      },
      providesTags: ['Macros'],
    }),

    createMacro: build.mutation<IMacro, Partial<IMacro>>({
      query: (body) => ({
        url: '/macros',
        method: 'POST',
        body,
      }),
      transformResponse: (response: { data: IMacro }) => {
        return response.data
      },
      invalidatesTags: ['Macros'],
    }),

    deleteMacro: build.mutation<ISimpleResponse, { id: IMacro['id'] }>({
      query: ({ id }) => ({
        url: `/macros/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (response: { data: ISimpleResponse }) => {
        return response.data
      },
      invalidatesTags: ['Macros'],
    }),

    updateMacro: build.mutation<IMacro, { id: IMacro['id']; body: Partial<IMacro> }>({
      query: ({ id, body }) => ({
        url: `/macros/${id}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (response: { data: IMacro }) => {
        return response.data
      },
      invalidatesTags: ['Macros'],
    }),

    getMacroNoteSections: build.query<IMacro['note_section_types'], void>({
      query: () => '/macros/note-sections',
      transformResponse: (response: { data: IMacro['note_section_types'] }) => {
        return response.data
      },
      providesTags: ['Macros'],
    }),
  }),
})

export const {
  useGetMacrosQuery,
  useCreateMacroMutation,
  useDeleteMacroMutation,
  useUpdateMacroMutation,
} = macrosApi
