import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { examTemplateTypeOptions } from '@/constants/data/options'

import type { IExamTemplatesState, IExamTemplatesStore } from '@/interfaces'

const initialData: IExamTemplatesState = {
  template: null,
  templateType: examTemplateTypeOptions[0][1],
  showForm: false,
}

export const useExamTemplatesStore = create<IExamTemplatesStore>()(
  devtools(
    (set) => ({
      ...initialData,

      setTemplate(template) {
        set({ template })
      },

      setTemplateType(templateType) {
        set({ templateType })
      },

      setShowForm(showForm) {
        set({ showForm })
      },

      reset() {
        set(initialData)
      },
    }),
    { name: 'ExamTemplatesStore' },
  ),
)
