import type { INoteDataItem } from '@/interfaces'

export function prepareItem(item: INoteDataItem): void {
  if (typeof item.content === 'string') {
    const value = item.content
    if (value && !value.startsWith('<') && value.includes('\n')) {
      item.content = value.replaceAll('\n', '<br>')
    }
  }
}
