import { createApi } from '@reduxjs/toolkit/query/react'

import { customToast } from '@/hooks'
import { baseQueryWithAuth } from '@/stores/redux/config'

import { convertBooleanToNumber, convertStringToBoolean } from '@/utils/convertTypes'

import type {
  IChangePasswordData,
  ISetPasswordData,
  ISimpleResponse,
  ITimezone,
  IUpdateUserSettingsData,
  IUploadLink,
  IUser,
  IUserSettingsItem,
} from '@/interfaces'

export const profileApi = createApi({
  baseQuery: baseQueryWithAuth,
  reducerPath: 'profileApi',
  endpoints: (build) => ({
    getProfile: build.query<IUser, void>({
      query: () => ({
        url: '/profile',
        method: 'GET',
      }),
      transformResponse: (baseQueryReturnValue: { data: IUser }) => {
        const res = {
          ...baseQueryReturnValue.data,
          settings: baseQueryReturnValue.data.settings.map((item) => ({
            ...item,
            value:
              typeof item.value === 'boolean' ? item.value : convertStringToBoolean(item.value),
          })),
        }
        return res
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        if (baseQueryReturnValue.data.type) {
          return baseQueryReturnValue.data
        }
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    getTimezone: build.query<ITimezone, void>({
      query: () => ({
        url: '/timezone',
        method: 'GET',
      }),
      transformResponse: (baseQueryReturnValue: ITimezone) => {
        return baseQueryReturnValue
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        if (baseQueryReturnValue.data.type) {
          return baseQueryReturnValue.data
        }
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    getUploadLink: build.mutation<IUploadLink, { filePath: string }>({
      query: ({ filePath }) => ({
        url: '/files/get-upload-url',
        params: { path: filePath },
        method: 'POST',
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    updateUser: build.mutation<IUser, Partial<IUser>>({
      query: (body) => ({
        url: '/profile',
        method: 'PUT',
        body,
      }),
      transformResponse: (baseQueryReturnValue: { data: IUser }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    setPassword: build.mutation<ISimpleResponse, ISetPasswordData>({
      query: (body) => ({
        url: '/user/set-password',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    changePassword: build.mutation<ISimpleResponse, IChangePasswordData>({
      query: (body) => ({
        url: '/user/change-password',
        method: 'PUT',
        body,
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    getUserSettings: build.query<IUserSettingsItem[], void>({
      query: () => ({
        url: '/user/settings',
        method: 'GET',
      }),
      transformResponse: (baseQueryReturnValue: {
        data: { field_name: string; value: '0' | '1' | boolean }[]
      }) => {
        return baseQueryReturnValue.data.map((item) => ({
          ...item,
          value: typeof item.value === 'boolean' ? item.value : convertStringToBoolean(item.value),
        }))
      },
    }),

    updateUserSettings: build.mutation<IUserSettingsItem[], IUpdateUserSettingsData>({
      query: (body) => ({
        url: '/user/settings',
        method: 'PUT',
        body: {
          ...body,
          ...(body.hpi_merging !== undefined && {
            hpi_merging: convertBooleanToNumber(body.hpi_merging),
          }),
          ...(body.hide_recording_permission_prompt !== undefined && {
            hide_recording_permission_prompt: convertBooleanToNumber(
              body.hide_recording_permission_prompt,
            ),
          }),
          ...(body.note_auto_submission_to_ehr !== undefined && {
            note_auto_submission_to_ehr: convertBooleanToNumber(body.note_auto_submission_to_ehr),
          }),
        },
      }),
      transformResponse: (baseQueryReturnValue: { data: IUserSettingsItem[] }) => {
        const res = baseQueryReturnValue.data.map((item) => ({
          ...item,
          value: typeof item.value === 'boolean' ? item.value : convertStringToBoolean(item.value),
        }))
        return res
      },
    }),
  }),
})

export const {
  useGetProfileQuery,
  useGetTimezoneQuery,
  useLazyGetProfileQuery,
  useUpdateUserMutation,
  useSetPasswordMutation,
  useGetUploadLinkMutation,
  useChangePasswordMutation,
  useGetUserSettingsQuery,
  useUpdateUserSettingsMutation,
} = profileApi
