import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'

import { hooklessLogout } from '@/hooks'
import { useAuthStore } from '@/stores/zustand'

interface IRefreshTokenResponse {
  token: string
  token_expires_at: string
}

const prepareHeaders = (headers: Headers, token: string | null) => {
  headers.set('Accept', 'application/json')
  if (token) headers.set('Authorization', `Bearer ${token}`)
  return headers
}

const mutex = new Mutex()

export const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_SERVER_URL,
  prepareHeaders(headers) {
    const token = useAuthStore.getState().token
    return prepareHeaders(headers, token)
  },
})

const refreshQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_SERVER_URL,
  prepareHeaders(headers) {
    const refresh_token = useAuthStore.getState().refresh_token
    return prepareHeaders(headers, refresh_token)
  },
})

export const baseQueryWithAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock()
  let result = await baseQuery(args, api, extraOptions)

  if (result.error?.status === 401) {
    if (mutex.isLocked()) {
      // Wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    } else {
      const release = await mutex.acquire()
      try {
        const refreshResult = (await refreshQuery(
          { url: '/refresh-token', method: 'GET' },
          api,
          extraOptions,
        )) as { data: IRefreshTokenResponse }

        if (refreshResult.data) {
          useAuthStore.getState().handleSetAuthData({ token: refreshResult.data.token })
          // Retry the original query with new token
          result = await baseQuery(args, api, extraOptions)
        } else {
          hooklessLogout(api.dispatch)
        }
      } finally {
        release()
      }
    }
  }

  return result
}

export default baseQueryWithAuth
