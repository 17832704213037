import { createApi } from '@reduxjs/toolkit/query/react'

import { customToast } from '@/hooks'
import { baseQueryWithAuth } from '@/stores/redux/config'

import type {
  ICreateOrganizationUserRequest,
  ICustomerPortalLink,
  IEditOrganizationUserRequest,
  IOrganization,
  IUser,
} from '@/interfaces'

export const organizationApi = createApi({
  baseQuery: baseQueryWithAuth,
  reducerPath: 'organizationApi',
  endpoints: (build) => ({
    getOrganizationDetails: build.query<IOrganization, string>({
      query: (id) => ({
        url: `/organizations/${id}`,
        method: 'GET',
      }),
      transformResponse: (baseQueryReturnValue: { data: IOrganization }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    generateCustomerPortalLink: build.mutation<ICustomerPortalLink, string>({
      query: (id) => ({
        url: `/organizations/${id}/customer-portal`,
        method: 'POST',
      }),
    }),

    updateOrganizationDetails: build.mutation<
      IOrganization,
      { id: string; body: Partial<IOrganization> }
    >({
      query: ({ id, body }) => ({
        url: `/organizations/${id}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (baseQueryReturnValue: { data: IOrganization }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    updateOrganizationUser: build.mutation<IUser, IEditOrganizationUserRequest>({
      query: ({ organization_id, user_id, body }) => ({
        url: `/organizations/${organization_id}/users/${user_id}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (baseQueryReturnValue: { data: IUser }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    deleteOrganizationUser: build.mutation<IUser, { organization_id: string; user_id: string }>({
      query: ({ organization_id, user_id }) => ({
        url: `/organizations/${organization_id}/users/${user_id}`,
        method: 'DELETE',
      }),
      transformResponse: (baseQueryReturnValue: { data: IUser }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    createOrganizationUser: build.mutation<IUser, ICreateOrganizationUserRequest>({
      query: ({ organization_id, body }) => ({
        url: `/organizations/${organization_id}/users`,
        method: 'POST',
        body,
      }),
      transformResponse: (baseQueryReturnValue: { data: IUser }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    resetOrganizationUserPassword: build.mutation<
      IUser,
      { organization_id: string; user_id: string }
    >({
      query: ({ organization_id, user_id }) => ({
        url: `/organizations/${organization_id}/users/${user_id}/reset-password`,
        method: 'POST',
      }),
      transformResponse: (baseQueryReturnValue: { data: IUser }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),
  }),
})

export const {
  useUpdateOrganizationUserMutation,
  useGenerateCustomerPortalLinkMutation,
  useGetOrganizationDetailsQuery,
  useUpdateOrganizationDetailsMutation,
  useDeleteOrganizationUserMutation,
  useCreateOrganizationUserMutation,
  useResetOrganizationUserPasswordMutation,
} = organizationApi
