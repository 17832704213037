import toast from 'react-hot-toast'

import type { IToast } from '@/interfaces'

// style
const style = {
  backgroundColor: 'rgb(255 255 255 / 0.75)',
  backdropFilter: 'blur(2rem)',
  fontFamily: 'var(--font-family-main), sans-serif',
  fontSize: '1.6rem',
  fontWeight: '600',
  color: '#000',
  lineHeight: '1.2',
  fill: '#000',
}

const persistentToast = (msg: string, type: 'success' | 'error' | 'loading') => {
  let toastId: string

  const show = () => {
    toast.dismiss()
    toastId = toast[type](msg, {
      position: 'top-center',
      duration: Number.POSITIVE_INFINITY,
      style,
    })
  }

  const hide = () => {
    toast.dismiss(toastId)
  }

  return { show, hide }
}

// toast
const customToast: IToast = (msg, type, promise, duration = 3000) => {
  type === 'promise'
    ? toast.promise(
        promise,
        {
          loading: 'Loading',
          success: 'Got the data',
          error: 'Error when fetching',
        },
        {
          position: 'top-center',
          style,
        },
      )
    : toast[type](msg, {
        position: 'top-center',
        duration,
        style,
      })
}

// toast hook
const useToast = () => {
  return { customToast, persistentToast }
}

export { customToast, persistentToast, useToast }
