import type React from 'react'
import { memo, useState } from 'react'
import cc from 'classcat'

import styles from './tooltip.module.scss'

interface TooltipProps {
  content: string | React.ReactNode
  shown?: boolean
  position?: 'top' | 'bottom' | 'left' | 'right'
  children: React.ReactElement
  classNameContainer?: string
  classNameTooltip?: string
  persistent?: boolean
}

const Tooltip: React.FC<TooltipProps> = memo(
  ({
    content,
    position = 'top',
    children,
    shown = true,
    classNameContainer,
    classNameTooltip,
    persistent = false,
  }: TooltipProps) => {
    const [isVisible, setIsVisible] = useState(false)

    if (!shown) return children

    return (
      <div
        className={cc([styles.tooltip__container, classNameContainer])}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => {
          if (!persistent) setIsVisible(false)
        }}
      >
        {children}
        {isVisible && (
          <div className={cc([styles.tooltip, styles[`tooltip__${position}`], classNameTooltip])}>
            {typeof content === 'string' ? (
              <div className={styles.tooltip__content}>{content}</div>
            ) : (
              content
            )}
          </div>
        )}
      </div>
    )
  },
)

Tooltip.displayName = 'Tooltip'

export default Tooltip
