import type { FC } from 'react'
import { lazy, Suspense } from 'react'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'react-redux'

import { store } from '@/stores/redux/store'

import AppPreloader from './app-preloader'

const AppRouter = lazy(() => import('./app-router'))

const App: FC = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<AppPreloader />}>
        <AppRouter />
      </Suspense>
      <Toaster />
    </Provider>
  )
}

export default App
