import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
// eslint-disable-next-line barrel-files/avoid-namespace-import
import * as Sentry from '@sentry/react'

import { envConfig } from '@/config'

import { App } from './app'

import './styles/globals.scss'

if (envConfig.ENABLE_SENTRY === 'true') {
  Sentry.init({
    dsn: 'https://591de03003ac0c70625aac37047566d9@o4506113290534912.ingest.us.sentry.io/4507495279558656',
    integrations: [],
  })
}

const root = createRoot(document.querySelector('#root') as HTMLElement)
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
)
