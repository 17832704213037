import { prepareItem } from '@/utils'

import type { INote } from '@/interfaces'

export function parseNoteData(note: INote): INote {
  const parsedNote = { ...note }
  const { data, original_data } = parsedNote

  parsedNote.parsedData = parseJsonOrDefault(data, [])
  parsedNote.parsedOriginalData = parseJsonOrDefault(original_data, [])

  // Preserve newlines in content
  for (const item of parsedNote.parsedData) {
    prepareItem(item)
  }
  for (const item of parsedNote.parsedOriginalData) {
    prepareItem(item)
  }

  return parsedNote
}

function parseJsonOrDefault(data: any, defaultValue: any[]): any[] {
  if (typeof data === 'string') {
    try {
      return JSON.parse(data)
    } catch {
      return defaultValue
    }
  }
  return data || defaultValue
}
