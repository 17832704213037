// format: [name, value]

import type { IExamTemplate, INoteType, INoteTypeComponent, IPatient } from '@/interfaces'

export const hospitalClinicOptions: [string, string][] = [
  ['Hospital Docs PA', 'Hospital Docs PA'],
  ['Medical Arts Hospital', 'Medical Arts Hospital'],
]

export const prefixOptions: [string, string][] = [
  ['Dr.', 'Dr.'],
  ['Mr.', 'Mr.'],
  ['Mrs.', 'Mrs.'],
  ['Ms.', 'Ms.'],
]

export const genderOptions: [string, Exclude<IPatient['gender'], undefined>][] = [
  ['Male', 'male'],
  ['Female', 'female'],
  ['Unspecified', 'unspecified'],
]

export const examTemplateTypeOptions: [string, IExamTemplate['type']][] = [
  ['ROS', 'ros'],
  ['Physical Exam', 'pe'],
]

export const visitTypeOptions: [string, INoteType['visit_type']][] = [
  ['Clinic', 'clinic'],
  ['First Visit', 'first visit'],
  ['Follow Up', 'follow up'],
  ['Inpatient', 'inpatient'],
  ['Office', 'office'],
]

export const specialityOptions: [string, INoteType['speciality']][] = [
  ['General', 'general'],
  ['Internal Medicine', 'internal medicine'],
  ['Functional Medicine', 'functional medicine'],
  ['Neurology', 'neurology'],
  ['Pain Management', 'pain management'],
  ['Rheumatology', 'rheumatology'],
]

export const shareOptions: [string, 'yes' | 'no'][] = [
  ['Yes', 'yes'],
  ['No', 'no'],
]

export const formatOptions: [string, INoteTypeComponent['format']][] = [
  ['List', 'list'],
  ['Narrative', 'narrative'],
  ['Problem Oriented', 'problem oriented'],
  ['Structured Template', 'structured template'],
]

export const formatOptionsWithExam: [string, INoteTypeComponent['format']][] = [
  ...formatOptions,
  ['Exam', 'exam'],
]

export const lengthOptions: [string, INoteTypeComponent['length']][] = [
  ['Concise', 'concise'],
  ['Normal', 'normal'],
  ['Verbose', 'verbose'],
]

export const NOTE_SECTIONS = {
  CC_HPI: 'CC/HPI',
  ROS: 'Review of Systems',
  PE: 'Physical Examination',
  AP: 'A/P',
}
