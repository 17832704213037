import { createApi } from '@reduxjs/toolkit/query/react'

import { customToast } from '@/hooks'
import { baseQueryWithAuth } from '@/stores/redux/config'

import type {
  IExamTemplate,
  IExamTemplateRes,
  INoteType,
  INoteTypeComponent,
  INoteTypeComponentReq,
  INoteTypeExamComponentReq,
  INoteTypeRes,
} from '@/interfaces'

function formatExamTemplate(examTemplate: IExamTemplateRes): IExamTemplate {
  const { is_shared, ...rest } = examTemplate

  return {
    ...rest,
    is_shared: Boolean(is_shared),
  }
}

function formatNoteType(noteType: INoteTypeRes): INoteType {
  const { is_active, is_default, is_shared, note_tune, ...rest } = noteType

  return {
    ...rest,
    is_active: Boolean(is_active),
    is_default: Boolean(is_default),
    is_shared: Boolean(is_shared),
    note_tune: Boolean(note_tune),
  }
}

export const noteTypesApi = createApi({
  baseQuery: baseQueryWithAuth,
  reducerPath: 'noteTypesApi',
  tagTypes: ['NoteType', 'ExamTemplate'],
  endpoints: (build) => ({
    getExamTemplates: build.query<IExamTemplate[], void>({
      query: () => ({
        url: '/exam-templates',
        method: 'GET',
      }),
      transformResponse: (baseQueryReturnValue: { data: IExamTemplateRes[] }) => {
        return baseQueryReturnValue.data.map((examTemplate) => formatExamTemplate(examTemplate))
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      providesTags: ['ExamTemplate'],
    }),

    getExamTemplate: build.query<IExamTemplate, { id: IExamTemplate['id'] }>({
      query: ({ id }) => ({
        url: `/exam-templates/${id}`,
        method: 'GET',
      }),
      transformResponse: (baseQueryReturnValue: { data: IExamTemplateRes }) => {
        return formatExamTemplate(baseQueryReturnValue.data)
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      providesTags: ['ExamTemplate'],
    }),

    createExamTemplate: build.mutation<IExamTemplate, { body: IExamTemplate }>({
      query: ({ body }) => ({
        url: '/exam-templates',
        method: 'POST',
        body,
      }),
      transformResponse: (baseQueryReturnValue: { data: IExamTemplateRes }) => {
        return formatExamTemplate(baseQueryReturnValue.data)
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['ExamTemplate'],
    }),

    editExamTemplate: build.mutation<
      IExamTemplate,
      { id: IExamTemplate['id']; body: Partial<IExamTemplate> }
    >({
      query: ({ id, body }) => ({
        url: `/exam-templates/${id}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (baseQueryReturnValue: { data: IExamTemplateRes }) => {
        return formatExamTemplate(baseQueryReturnValue.data)
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['ExamTemplate'],
    }),

    deleteExamTemplate: build.mutation<IExamTemplate, { id: IExamTemplate['id'] }>({
      query: ({ id }) => ({
        url: `/exam-templates/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (baseQueryReturnValue: { data: IExamTemplateRes }) => {
        return formatExamTemplate(baseQueryReturnValue.data)
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['ExamTemplate'],
    }),

    getNoteTypes: build.query<INoteType[], void>({
      query: () => ({
        url: '/note-types',
        method: 'GET',
      }),
      transformResponse: (baseQueryReturnValue: { data: INoteTypeRes[] }) => {
        return baseQueryReturnValue.data.map((noteType) => formatNoteType(noteType))
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      providesTags: ['NoteType'],
    }),

    getNoteType: build.query<INoteType, { id: INoteType['id'] }>({
      query: ({ id }) => ({
        url: `/note-types/${id}`,
        method: 'GET',
      }),
      transformResponse: (baseQueryReturnValue: { data: INoteTypeRes }) => {
        return formatNoteType(baseQueryReturnValue.data)
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      providesTags: ['NoteType'],
    }),

    createNoteType: build.mutation<INoteType, { body: INoteType }>({
      query: ({ body }) => ({
        url: '/note-types',
        method: 'POST',
        body: {
          ...body,
          // NOTE: is_active doesn't have checkbox on form
          is_active: true,
        },
      }),
      transformResponse: (baseQueryReturnValue: { data: INoteTypeRes }) => {
        return formatNoteType(baseQueryReturnValue.data)
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['NoteType'],
    }),

    editNoteType: build.mutation<INoteType, { id: INoteType['id']; body: Partial<INoteType> }>({
      query: ({ id, body }) => ({
        url: `/note-types/${id}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (baseQueryReturnValue: { data: INoteTypeRes }) => {
        return formatNoteType(baseQueryReturnValue.data)
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['NoteType'],
    }),

    deleteNoteType: build.mutation<INoteType, { id: INoteType['id'] }>({
      query: ({ id }) => ({
        url: `/note-types/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (baseQueryReturnValue: { data: INoteTypeRes }) => {
        return formatNoteType(baseQueryReturnValue.data)
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['NoteType'],
    }),

    createNoteTypeComponent: build.mutation<INoteTypeComponent, { body: INoteTypeComponentReq }>({
      query: ({ body }) => ({
        url: '/note-type-components',
        method: 'POST',
        body,
      }),
      transformResponse: (baseQueryReturnValue: { data: INoteTypeComponent }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['NoteType'],
    }),

    editNoteTypeComponent: build.mutation<
      INoteTypeComponent,
      { id: INoteType['id']; body: Partial<INoteTypeComponentReq> }
    >({
      query: ({ id, body }) => ({
        url: `/note-type-components/${id}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (baseQueryReturnValue: { data: INoteTypeComponent }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['NoteType'],
    }),

    deleteNoteTypeComponent: build.mutation<INoteTypeComponent, { id: INoteType['id'] }>({
      query: ({ id }) => ({
        url: `/note-type-components/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (baseQueryReturnValue: { data: INoteTypeComponent }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['NoteType'],
    }),

    setComponentsOrder: build.mutation<
      INoteType,
      { id: INoteType['id']; componentIds: INoteTypeComponent['id'][] }
    >({
      query: ({ id, componentIds }) => ({
        url: `/note-types/${id}/setComponentsOrder`,
        method: 'POST',
        body: {
          components: componentIds,
        },
      }),
      transformResponse: (baseQueryReturnValue: { data: INoteTypeRes }) => {
        return formatNoteType(baseQueryReturnValue.data)
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['NoteType'],
    }),

    createNoteTypeExamComponent: build.mutation<
      INoteTypeComponent,
      { body: INoteTypeExamComponentReq }
    >({
      query: ({ body }) => ({
        url: '/note-type-components',
        method: 'POST',
        body: {
          ...body,
          format: 'exam',
          length: 'normal',
          definition: '-',
        },
      }),
      transformResponse: (baseQueryReturnValue: { data: INoteTypeComponent }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['NoteType'],
    }),

    editNoteTypeExamComponent: build.mutation<
      INoteTypeComponent,
      { id: INoteType['id']; body: Partial<INoteTypeExamComponentReq> }
    >({
      query: ({ id, body }) => ({
        url: `/note-type-components/${id}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (baseQueryReturnValue: { data: INoteTypeComponent }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['NoteType'],
    }),

    getNoteTypeComponent: build.query<INoteTypeComponent, { id: INoteTypeComponent['id'] }>({
      query: ({ id }) => ({
        url: `/note-type-components/${id}`,
        method: 'GET',
      }),
      transformResponse: (baseQueryReturnValue: { data: INoteTypeComponent }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    processTemplate: build.mutation<INoteTypeComponent, { id: number }>({
      query: ({ id }) => ({
        url: `/note-type-components/${id}/process-template`,
        method: 'POST',
      }),
    }),

    bulkDeleteNoteType: build.mutation<INoteTypeComponent, { note_types: number[] }>({
      query: ({ note_types }) => ({
        url: '/bulk-actions/note-types',
        method: 'DELETE',
        body: {
          note_types,
        },
      }),
    }),
  }),
})

export const {
  useGetExamTemplatesQuery,
  useGetExamTemplateQuery,
  useCreateExamTemplateMutation,
  useEditExamTemplateMutation,
  useDeleteExamTemplateMutation,
  useGetNoteTypesQuery,
  useGetNoteTypeQuery,
  useCreateNoteTypeMutation,
  useEditNoteTypeMutation,
  useDeleteNoteTypeMutation,
  useCreateNoteTypeComponentMutation,
  useEditNoteTypeComponentMutation,
  useDeleteNoteTypeComponentMutation,
  useSetComponentsOrderMutation,
  useCreateNoteTypeExamComponentMutation,
  useEditNoteTypeExamComponentMutation,
  useGetNoteTypeComponentQuery,
  useProcessTemplateMutation,
  useBulkDeleteNoteTypeMutation,
} = noteTypesApi
