import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import type { ThunkDispatch } from '@reduxjs/toolkit'

import {
  authApi,
  encountersApi,
  feedbackApi,
  noteTypesApi,
  patientsApi,
  profileApi,
} from '@/stores/redux/apis'
import { useAuthStore, useTimerStore } from '@/stores/zustand'

export function useLogout() {
  const dispatch = useDispatch()

  const handleLogout = useAuthStore((state) => state.handleLogout)
  const resetTimers = useTimerStore((state) => state.resetTimers)

  const logout = useCallback(() => {
    handleLogout()
    resetTimers()

    const apis = [authApi, encountersApi, feedbackApi, noteTypesApi, patientsApi, profileApi]
    for (const api of apis) {
      dispatch(api.util.resetApiState())
    }
  }, [dispatch, handleLogout, resetTimers])

  return logout
}

export function hooklessLogout(dispatch: ThunkDispatch<any, any, any>) {
  useAuthStore.getState().handleLogout()
  useTimerStore.getState().resetTimers()

  const apis = [authApi, encountersApi, feedbackApi, noteTypesApi, patientsApi, profileApi]
  for (const api of apis) {
    dispatch(api.util.resetApiState())
  }
}
