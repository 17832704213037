import { createApi } from '@reduxjs/toolkit/query/react'

import { customToast } from '@/hooks'
import { baseQueryWithAuth } from '@/stores/redux/config'

import type {
  IAddPatientMutation,
  IBackgroundJob,
  IDataWithMeta,
  IEditPatientMutation,
  IEncounter,
  IPatient,
  IPatientsQuery,
  ISimpleResponseWithJobId,
} from '@/interfaces'

export const patientsApi = createApi({
  baseQuery: baseQueryWithAuth,
  reducerPath: 'patientsApi',
  tagTypes: ['Encounter', 'Patient'],
  endpoints: (build) => ({
    getPatients: build.query<
      IDataWithMeta<IPatient[]>,
      { page: number; limit: number; is_global?: 0 | 1 }
    >({
      query: (params) => ({
        url: '/patients',
        params,
        method: 'GET',
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      serializeQueryArgs: ({ queryArgs: { page, ...args } }) => args,
      merge: (currentCache, newItems) => {
        if (newItems.meta.current_page === 1) {
          currentCache.data = newItems.data
        } else {
          currentCache.data.push(...newItems.data)
        }
      },
      forceRefetch: ({ currentArg, previousArg }) => currentArg?.page !== previousArg?.page,
    }),

    getPatient: build.query<IPatient, { id: IPatient['id'] }>({
      query: ({ id }) => ({
        url: `/patients/${id}`,
        method: 'GET',
      }),
      transformResponse: (baseQueryReturnValue: { data: IPatient }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    getPatientEncounters: build.query<IEncounter[], { id: IPatient['id'] }>({
      query: ({ id }) => ({
        url: `/patients/${id}/encounters`,
        method: 'GET',
      }),
      providesTags: ['Encounter'],
      transformResponse: (baseQueryReturnValue: { data: IEncounter[] }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    searchPatients: build.query<IDataWithMeta<IPatient[]>, IPatientsQuery>({
      query: (params) => ({
        url: '/patients/search',
        params,
        method: 'GET',
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        const { message, errors } = baseQueryReturnValue.data as {
          message: string
          errors: Record<string, string[]>
        }

        if (errors == null || Object.keys(errors).length === 0) {
          customToast(message, 'error')
        }
        return errors
      },
      serializeQueryArgs: ({ queryArgs: { page, ...args } }) => args,
      merge: (currentCache, newItems) => {
        if (newItems.meta.current_page === 1) {
          currentCache.data = newItems.data
        } else {
          currentCache.data.push(...newItems.data)
        }
      },
      forceRefetch: ({ currentArg, previousArg }) => currentArg?.page !== previousArg?.page,
    }),

    recentPatients: build.query<IPatient[], void>({
      query: () => ({
        url: '/patients/recent',
        method: 'GET',
      }),
      transformResponse: (baseQueryReturnValue: { data: IPatient[] }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    importCsv: build.mutation<ISimpleResponseWithJobId, string[]>({
      query: (file_paths) => ({
        url: '/patients/import-csv',
        method: 'POST',
        body: {
          file_paths,
        },
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Patient'],
    }),

    getImportStatus: build.query<IBackgroundJob, { id: string }>({
      query: ({ id }) => ({
        url: `/patients/import-status/${id}`,
        method: 'GET',
      }),
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
    }),

    addPatient: build.mutation<IPatient, IAddPatientMutation>({
      query: (body) => ({
        url: '/patients',
        method: 'POST',
        body,
      }),
      transformResponse: (baseQueryReturnValue: { data: IPatient }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Patient'],
    }),

    editPatient: build.mutation<IPatient, IEditPatientMutation>({
      query: ({ id, ...body }) => ({
        url: `/patients/${id}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (baseQueryReturnValue: { data: IPatient }) => {
        return baseQueryReturnValue.data
      },
      transformErrorResponse: (baseQueryReturnValue: any) => {
        customToast(baseQueryReturnValue.data.message, 'error')
      },
      invalidatesTags: ['Patient'],
    }),
  }),
})

export const {
  useGetPatientQuery,
  useGetPatientsQuery,
  useGetPatientEncountersQuery,
  useSearchPatientsQuery,
  useRecentPatientsQuery,
  useImportCsvMutation,
  useGetImportStatusQuery,
  useLazyGetImportStatusQuery,
  useAddPatientMutation,
  useEditPatientMutation,
} = patientsApi
